<template>
  <div>
    <th-wrapper :title="$t('pages.settings.receipts.digital_receipts.title')">
      <el-switch
        v-model="digitalReceipt"
        :aria-label="$t('pages.settings.receipts.digital_receipts.title')"
        :active-text="$t('common.interactions.switches.enabled_capital')"
      />
    </th-wrapper>
  </div>
</template>

<script>
import get from 'just-safe-get'

export default {
  computed: {
    digitalReceipt: {
      get: function () {
        return get(
          this.$store.getters['Config/getClientAccountConfiguration'],
          'receipts.digital_receipt.enabled'
        )
      },
      set: function (newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'receipts.digital_receipt.enabled',
          value: newValue ?? false
        })
      }
    }
  }
}
</script>
